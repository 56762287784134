<template>
  <div class="pa-2">
    <loading-overlay :active="loadingOverlay" :is-full-page="true" loader="bars" />
    <CRow>
      <CCol sm="6" lg="3">
        <label><b class="text-danger">*</b> {{$t('label.service')}}</label>
        <v-select
          :filter="fuseSearchService"
          :options="serviceOptions"
          v-model="$v.ServiceId.$model"
          @input="act_serviceid"
          :placeholder="$t('label.select')"
        />
      </CCol>
      <CCol sm="6" lg="2">
        <label><b class="text-danger">*</b> {{$t('label.frecuency')}}</label>
        <v-select
          :filter="fuseSearchFrecuency"
          :options="computedFrecuencyList"
          :invalid-feedback="$t('label.required')"
          v-model.trim="$v.FrecuencyId.$model"
          :is-valid="hasError($v.FrecuencyId)"
          :placeholder="$t('label.select')"
        />
      </CCol>
      <CCol sm="6" lg="4">
        <label><b class="text-danger">*</b> {{$t('label.port')}}</label>
        <div class="input-group">
          <v-select
            :filter="fuseSearchPort"
            :options="computedPortList"
            :invalid-feedback="$t('label.required')"
            v-model.trim="$v.PortId.$model"
            :is-valid="hasError($v.PortId)"
            :placeholder="$t('label.select')"
            class="w-85"
          />
          <div class="input-group-append" id="button-addon2">
            <CButton
              color="dark"
              size="sm"
              @click="nuevoPuerto(false)"
            >
              <CIcon name="cil-playlist-add" />
            </CButton>
          </div>
        </div>
      </CCol>
      <CCol sm="4" lg="2">
        <CSelect
          v-model="$v.Sequence.$model"
          :value.sync="Sequence"
          :options="[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20]"
          :is-valid="hasError($v.Sequence)"
          :placeholder="$t('label.select')"
          :label="$t('label.sequence')"
          addLabelClasses="required"
        />
      </CCol>
      <CCol sm="2" lg="1" class="pt-4">
        <CButton
          color="edit"
          size="sm" 
          class="mt-2" 
          :disabled="desactivado"
          @click="addServicePort"
          v-c-tooltip="{content: $t('label.edit')}"
          v-if="ServicePortId"
        >
          <CIcon name="pencil" />
        </CButton>
        <CButton
          color="add" 
          size="sm" 
          class="mt-2" 
          v-c-tooltip="{content: $t('label.add'),placement: 'top'}" 
          :disabled="desactivado" 
          @click="addServicePort"
          v-if="!ServicePortId"
        >
          <CIcon name="checkAlt" />
        </CButton>
        <CButton 
          class="ml-1 mt-2" 
          color="wipe" 
          size="sm" 
          v-c-tooltip="{content: $t('label.clearFields')}"
          @click="resetData" >
          <CIcon name="cil-brush-alt"/>
        </CButton>
      </CCol>
      <CCol sm="12" v-if="newPort">
        <AddPort :value="PortId" @child-refresh="nuevoPuerto" />
      </CCol> 
      <!--<CCol sm="6" lg="8" class="text-right">
        <CButton
          square
          color="dark"
          @click="toggleShowModalCrearServicio"
        >
          <CIcon name="cil-playlist-add" /><span class="ml-1"
            >{{$t('label.affiliate')+$t('label.port')}}</span
          >
        </CButton>
      </CCol>-->
    </CRow>
    <dataTableExtended
     class="align-center-row-datatable"
      size="lg"
      :fields="fields"
      :items="computedPortServicesList"
      :items-per-page="5"
      column-filter
      pagination
      :table-filter="tableText.tableFilterText"
      :items-per-page-select="tableText.itemsPerPageText"
      :noItemsView="tableText.noItemsViewText"
      :loading="loadingTable"
      sorter
      hover
       
    >
      <template #loading>
        <loading/>
      </template>
      <template #Status="{ item }">
        <td class="center-cell">
          <CBadge :color="getBadge(item.Status)">
            {{ $t('label.'+item.Status) }}
          </CBadge>
        </td>
      </template>
      <template #options="{ item }">
        <td class="center-cell">
            <CButton
              color="edit"
              size="sm"
              v-c-tooltip="{
                content: $t('label.edit')+$t('label.affiliate')+'\n'+$t('label.port'),
                placement: 'top',
              }"
              @click="editarDatos(item)"
            >
              <CIcon name="pencil" />
            </CButton>
            <CButton
              color="wipe ml-1"
              size="sm"
              v-c-tooltip="{
                content: $t('label.delete')+$t('label.affiliate')+'\n'+$t('label.port'),
                placement: 'top',
              }"
              @click="ConfirmarEliminado(item)"
            >
              <CIcon name="cil-trash" />
            </CButton>
        </td>
      </template>
    </dataTableExtended>
    <!--<modalPuerto
      :modal.sync="showModal"
      @updated-modal="updateCLoseModal"
      :title="titleModal"
      :editModal="editModal"
      :serviceSelected="serviceSelected"
      @refresh-data-table="mountedPortServiceList(ServiceId.value)"
    />-->
  </div>
</template>
<script>
import { DateFormater } from '@/_helpers/funciones';
import GeneralMixin from '@/_mixins/general';
//import modalPuerto from './modal-puerto';
import UpperCase from '@/_validations/uppercase-directive';
import puerto from '@/_validations/servicio/puerto';
import AddPort from '@/pages/puerto/add-puerto';
import Fuse from "fuse.js";
import { mapState } from 'vuex';

function fields() {
  return [
    { label: '#', key: 'Nro', filter:false, _style: 'width:3%; text-align:center;', _classes: 'text-center',  },
    {
      label: this.$t('label.service'),
      key: 'ServiceCode',
      _classes: 'text-uppercase',
       _style: 'width:20%;'
    },
    {
      label: this.$t('label.port'),
      key: 'PortName',
      _classes: 'text-uppercase',
      _style: 'width:30%;'
    },
    {
      label: this.$t('label.frecuency'),
      key: 'TimeFrecuencyName',
      _classes: 'text-uppercase',
      _style: 'width:10%;'
    },
    { label: this.$t('label.user'), key: 'TransaLogin', _classes: 'text-uppercase', _style: 'width:8%;' },
    { label: this.$t('label.date'), key: 'FormatedDate', _classes:'text-center', _style: 'width:10%;' },
    { label: this.$t('label.status'), key: 'Status', _classes:'text-center', _style: 'width:8%;' },
    { key: 'options', label: '', sorter: false, filter: false,_style: 'text-align:center; min-width:100px; width:10%;' },
  ];
}

function data() {
  return {
    //MODEL
    PortId: '',
    ServiceId: '',
    FrecuencyId: '',
    Sequence: '',
    Status: 1,
    //INFO
    itemsPuerto: [],
    //SELECT
    services: [],
    newPort: false,
    itemsFrecuency: [],
    itemsPorts: [],
    loadingTable: false,
    loadingOverlay: false,
    titleModal: '',
    showModal: false,
    editModal: false,
    serviceSelected: {},
    ServicePortId: '',
  };
}
/*
function updateCLoseModal(event) {
  this.showModal = false;
  this.titleModal = '';
  this.editModal = false;
  this.serviceSelected = {};
}

//## Activa el modal
function toggleShowModalCrearServicio() {
  this.showModal = true;
  this.titleModal = this.$t('label.affiliate')+this.$t('label.port');
}

//## Activa el modal
function toggleShowModalEditService(item) {
  this.showModal = true;
  this.titleModal = this.$t('label.edit')+this.$t('label.affiliate')+this.$t('label.port');
  this.editModal = true;
  this.serviceSelected = item;
}
*/

function ConfirmarEliminado(item) {
  //this.resetData();return false;
  this.$swal.fire({
    text: `${this.$t('label.changeStatusQuestion')} ${item.Nro}?`,
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#42AA91',
    cancelButtonColor: '#E1373F',
    confirmButtonText: this.$t('button.confirm'),
    cancelButtonText: this.$t('button.cancel')
  }).then((result) => {
    if (result.isConfirmed) {
      this.loadingOverlay = true;
      let ServicePortJson = {
        ServiceId: item.ServiceId,
        PortId: item.PortId,
        FrecuencyId: item.FrecuencyId,
        Sequence: item.Sequence,
        Status: 0
      };

      this.$http.post('ServicePort-insert', ServicePortJson, {root: 'ServicePortJson'})
      .then((response) => {
        const messageSuccess = response.data.data[0].Response;
        this.mountedPortServiceList(item.ServiceId);
        this.loadingOverlay = false;
        this.resetData();
        this.$notify({
          group: 'container',
          title: '¡Solicitud Exitosa!',
          text: messageSuccess,
          type: 'success',
        });
      })
      .catch((e) => {
        this.loadingOverlay = false;
        this.$notify({
          group: 'container',
          title: '¡Error!',
          text: e,
          type: 'error',
        });
      });
    }
  });  
}

async function addServicePort() {
  if (this.$v.$invalid) return false;
  this.loadingOverlay = true;
  let ServicePortJson = [];
  let metodo      = '';
  let ruta        = '';

  if (this.ServicePortId!="") {
    ServicePortJson = {
      ServicePortId: this.ServicePortId,
      ServiceId: this.ServiceId.value,
      PortId: this.PortId.value,
      FrecuencyId: this.FrecuencyId.value,
      Sequence: this.Sequence,
      Status: 1
    };

    metodo = 'PUT';
    ruta = "ServicePort-update";
  } else {
    ServicePortJson = {
      ServiceId: this.ServiceId.value,
      PortId: this.PortId.value,
      FrecuencyId: this.FrecuencyId.value,
      Sequence: this.Sequence,
      Status: 1
    };

    metodo = 'POST';
    ruta = "ServicePort-insert";
  }
  
  await this.$http.ejecutar(metodo, ruta, ServicePortJson, {root: 'ServicePortJson'})
    .then((response) => {
      if (response.status === (200 || 201)) {
        const messageSuccess = response.data.data[0].Response;
        this.mountedPortServiceList(this.ServiceId.value);
        this.resetData();
        this.loadingOverlay = false;
        this.$notify({
          group: 'container',
          title: '¡Solicitud Exitosa!',
          text: messageSuccess,
          type: 'success',
        });
      }
    })
    .catch((e) => {
      this.loadingOverlay = false;
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: e,
        type: 'error',
      });
    });
}

function resetData() {
  this.Sequence = '';
  this.Status = 1;
  this.FrecuencyId = '';
  this.PortId = '';
  this.ServicePortId = '';
  this.$nextTick(() => { this.$v.$reset() })
}

//### Funcion mounted para cargar los datos de la tabla ###
async function mountedPortServiceList(idService) {
  if (typeof(idService) == "undefined") return false;
  this.loadingTable = true;

  this.$http
    .get('ServicePort-list?ServiceId='+idService)
    .then((response) => (this.itemsPuerto = response.data.data))
    .finally(() => {
      this.loadingOverlay = false;
      this.loadingTable = false;
    })
    .catch((e) => {
      this.loadingOverlay = false;
      this.loadingTable = false;
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: e,
        type: 'error',
      });
    });
}

function computedPortServicesList() {
  if (this.itemsPuerto.length > 0) {
    return this.itemsPuerto.map((item) => {
      return {
        ...item,
        FormatedDate: item.TransaRegDate
          ? DateFormater.formatOnlyDateWithSlash(item.TransaRegDate)
          : 'N/A',
        _classes: item.Status != 'ACTIVO' ? 'table-danger' : '',
      };
    });
  }
}

function editarDatos(item) {
  this.Sequence = item.Sequence;
  this.Status = 1;
  this.FrecuencyId = {
    value: item.FrecuencyId,
    label: item.TimeFrecuencyName,
  }
  this.PortId = {
    label: item.PortName,
    value: item.PortId,
  }
  this.ServicePortId = item.ServicePortId;
}

function listarServicios () {
  let listado = Array;
  this.services = [];
  this.$http.get('Service-list', { CompanyBranchId: this.branch.CompanyBranchId, Filter: 'ACTIVO' })
  .then(response => {
    listado = [...response.data.data];
    this.services = listado.filter(ob => ob.Status==="ACTIVO" );
  }).catch( err => {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
    });
  });
}

function fuseSearchService(options, search) {
  let fuse = new Fuse(options, {
    keys: ["ServiceCode"],
    shouldSort: true
  });
  return search.length
    ? fuse.search(search).map(({ item }) => item)
    : fuse.list;
}

function serviceOptions() {
  return this.services.map((ob) => Object.assign({}, ob, {
    label: ob.ServiceCode,
    value: ob.ServiceId
  }));
}

function computedFrecuencyList() {
  return this.itemsFrecuency.map((item) => Object.assign({}, item, {
    label: item.TimeFrecuencyName,
    value: item.TimeFrecuencyId,
  }));
}

function fuseSearchFrecuency(options, search) {
  let fuse = new Fuse(options, {
    keys: ["TimeFrecuencyName"],
    shouldSort: true
  });
  return search.length
    ? fuse.search(search).map(({ item }) => item)
    : fuse.list;
}

async function mountedFrecuencyList() {
  this.loadingOverlay = true;
  await this.$http
    .get('TimeFrecuency-list?Filter=ACTIVO')
    .then((response) => {
      this.itemsFrecuency = response.data.data.filter(ob => ob.Status==="ACTIVO" );
      this.loadingOverlay = false;
    })
    .catch((e) => {
      this.loadingOverlay = false;
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: e,
        type: 'error',
      });
    });
}

async function mountedPortList() {
  this.loadingOverlay = true;
  this.itemsPorts = [];

  await this.$http
    .get('Port-list?CountryId&Filter=ACTIVO')
    .then((response) => {
      this.itemsPorts = response.data.data.sort((a, b) => (a.PortName > b.PortName) ? 1 : -1);
      this.loadingOverlay = false;
    })
    .catch((e) => {
      this.loadingOverlay = false;
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: e,
        type: 'error',
      });
    });
}

function computedPortList() {
  return this.itemsPorts.map((item) => Object.assign({}, item, {
    label: item.PortName+' ('+item.CountryJson[0].IsoCodeAlpha2+')',
    value: item.PortId,
  }));
}

function fuseSearchPort(options, search) {
  let fuse = new Fuse(options, {
    keys: ["PortName"],
    shouldSort: true
  });
  return search.length
    ? fuse.search(search).map(({ item }) => item)
    : fuse.list;
}

function act_serviceid(newService) {
  this.resetData();
  if (newService) {
    this.$store.commit('servicio/set', { value: newService.value, label: newService.label });
  } else {
    this.$store.commit('servicio/set', '');
  }  
}

function nuevoPuerto(item) {
  if (this.newPort) {
    this.newPort = false;
    this.mountedPortList();
  } else {
    this.newPort = true;
  }  
}

export default {
  name: 'indexPuerto',
  data,
  props: {
    tabValue: null,
  },
  mixins: [GeneralMixin],
  directives: UpperCase,
  validations: puerto,
  methods: {
    mountedPortServiceList,
    ConfirmarEliminado,
    fuseSearchService,
    fuseSearchFrecuency,
    fuseSearchPort,
    listarServicios,
    act_serviceid,
    mountedFrecuencyList,
    mountedPortList,
    addServicePort,
    resetData,
    nuevoPuerto,
    editarDatos
  },
  components: {
    AddPort
  },
  mounted() {
    //this.mountedPortServiceList(),
    this.listarServicios();
    this.mountedFrecuencyList();
    this.mountedPortList();
  },
  watch: {
    ServicioId: function(newService) {
      if(newService){
        this.ServiceId = {
          value: newService.value,
          label: newService.label
        }
        this.mountedPortServiceList(newService.value);
      } else {
        this.ServiceId = "";
        this.itemsPuerto = [];
      }
    },
    ServiceId: function(nuevo) {
      if (nuevo != null) {
        this.mountedPortServiceList(nuevo.value);
      }        
    },
    actList: function(nuevo) {
      this.listarServicios();
    },
    tabValue: async function (newValue, OldValue) {
      if(newValue == 1){
        this.listarServicios();
        this.mountedFrecuencyList();
        this.mountedPortList();
      }
    },
  },
  computed: {
    computedPortServicesList,
    serviceOptions,
    computedFrecuencyList,
    computedPortList,
    fields,
    ...mapState({
        ServicioId: state => state.servicio.servicioId,
        actList: state => state.servicio.actList,
        user: state => state.auth.user,
        branch: (state) => state.auth.branch,
    })
  },
};
</script>
<style lang="scss">
  .append {
    position: sticky;
    bottom: 0;
    background: white;
  }

  .w-85 {
    width: 85% !important;
  }

  .center-cell {
  text-align: center;
}
 
</style>
